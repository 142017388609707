<template>
    <div class="content">
        <sb-search
                @onSubmit="onSubmit"
                @exportExcel="exportExcel"
                @reset="reset"
        >
        </sb-search>
<!--     border="1px"   -->
		<el-alert
			:title="'该页面总费用：'+feeb.totalCostPayable+' 元；总单量：'+feeb.publishTotal+'单'"  >
		</el-alert>
        <table  width="100%" style="border-collapse: collapse;" >
            <!--     表格头部       -->
            <tr align="center" class="headTr border"  >
                <th  v-for="(item,index) in column" :key="index">
                    <div class="headTrContent" style="padding: 12px 0">
                        {{item.title}}
                    </div>
                </th>
            </tr>
            <!--     表格主体      -->
            <tbody v-for="(item,index) in taskList" :key="index" style="margin: 10px 0;border-bottom: 1px solid  rgb(224,224,224);">

                <tr >

                    <td  class="td " style="padding-left:  0">
                      <div style="width: 340px;font-size:12px;margin-bottom: 5px;overflow: hidden;text-overflow: ellipsis;white-space: nowrap">
                        {{item.goodsTitle}}
                      </div>
                        <div class="base">
                          <el-image
                              style="width: 50px; height: 50px"
                              :src="item.goodsPicture"
                              fit="fit">
                          </el-image>
                            <div class="baseInfo">
                              <div class="title"  style="margin-bottom: 3px;">
                                商品编号：{{item.goodsNo}}
                                <i class="el-icon-link" @click="goHref(item.goodsLink)" style="cursor: pointer"></i>
                              </div>
                              <div class="title"  style="margin-bottom: 3px;">
                                任务ID：{{item.taskNum}}
                              </div>
                                <div style="display: flex;align-items: center;margin-bottom: 3px;">
                                    <el-tag size="mini" style="margin-right: 5px">{{item.platformName}}</el-tag>
                                    <span class="overflow" style="width: 140px">{{item.shopName}}</span>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="td " align="center">
                        <div >
                          {{ item.payablePrice }}/{{ item.serviceFee }}
                        </div>
                    </td>
                    <td class="td " align="center">
                        <div >
                          {{ item.totalCostPayable }}
                        </div>
                    </td>

                    <td class="td "  align="center">
                        <span>{{item.typeName}}</span>
                    </td>
                    <td class="td "  align="center">
                      <div>总单量：{{item.publishTotal}}</div>
                      <div>剩余量：{{item.residueTotal}}</div>
                    </td>
                    <td class="td "  align="center">
                        <span>{{item.paymentTime}}</span>
                    </td>
                    <td class="td "  align="center">
                        <el-tag :color="item.statusStyle" size="mini" style="color: #fff">
                            {{item.statusLabel}}
                        </el-tag>
                    </td>
                    <td class="td "  align="center">
                        <div class="active">
                            <div>
                                <el-link type="primary" @click="seeBillInfo(item)">账单详情</el-link>
                            </div>
                            <div>
                              <el-link type="primary" @click="copyTask(item)">复制任务</el-link>
                            </div>
                            <div v-if="item.status == 1">
                                <el-link type="primary" @click="edit(item)">编辑任务</el-link>
                            </div>
                            <div v-if="item.status == 1">
                                <el-link type="primary" @click="pushTask(item)">发布任务</el-link>
                            </div>
                            <div v-if="item.status == 1">
                                <el-link type="primary" @click="delectTask(item)">删除任务</el-link>
                            </div>
<!--                           v-if="item.status == 6"-->
                            <div v-if="item.status == 6">
                              <el-link type="primary" @click="exportAppraise(item)" >导入评价</el-link>
                            </div>

                            <div v-if="item.status == 6">
                              <el-link type="primary" @click="bathImport(item)">上传评价</el-link>
                            </div>
                        </div>
                    </td>
                </tr>

            </tbody>
        </table>
        <!-- 分页-->
        <div style="height: 65px">
            <el-pagination
                style="float: right;margin-top: 20px"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :page-sizes="[50, 200, 800, 1200]"
                layout="total, pager,  sizes"
                :total="total">
            </el-pagination>
        </div>
      <bill-info ref="billInfo"></bill-info>
      <export-appraise-modal ref="exportAppraiseModal"></export-appraise-modal>
      <bath-import-appraise ref="bathImportAppraise"></bath-import-appraise>
    </div>
</template>
<script>
    import {TASK,ImgUrl,COPY,PUSH,LOGIC,} from "../../api/base";
    import SbSearch from './components/searForm'
    import BillInfo from './components/billInfo'
    import ExportAppraiseModal from  '../evaluate/components/exportAppraiseModal'
    import BathImportAppraise from  '../evaluate/components/bathImportAppraise'

    const column =[
        {
            title: '商品',
        },
        {
            title: '实付/服务费',
        },
        {
            title: '费用总额',
        },
        {
            title: '任务类型',
        },
        {
            title: '任务进展',
        },
        {
            title: '成交时间',
        },
        {
            title: '状态',
        },
        {
            title: '操作',
        },
    ]

    export default {
        name: "orderInfo",
        data() {
            return {
               flagForm:{
                 orderId:'',
                 pushFlag:'',
                 pushNotes:'',
               },
                showPopver:false,
                allFlag:false,
                loading:false,
                column,
                falgColor:'',
                taskList:[],
                listForm:{
                    pageIndex:1,
                    pageSize:50,
                },
                total:0,
            }
        },
        computed:{
            feeb(){
              const map = new Map()
              map.totalCostPayable= 0;
              map.publishTotal= 0;
              this.taskList.forEach(item=>{
                if(item.totalCostPayable){
                 map.totalCostPayable = map.totalCostPayable + item.totalCostPayable;
                }
                if(item.totalCostPayable){
                  map.publishTotal = map.publishTotal + item.publishTotal;
                }
              });
              map.totalCostPayable = map.totalCostPayable.toFixed(2);
              map.publishTotal = parseInt(map.publishTotal) ;
              return map;
            },
            selectList(){
                const arr = [];
                this.taskList.forEach(item=>{
                    if(item.flag){
                        arr.push(item)
                    }
                });

                return arr;
            },
            selectId(){
                const  idList = [];
                this.taskList.forEach(item=>{
                    if(item.flag){
                        idList.push(item.id)
                    }
                })
                return idList
            },
        },

        methods: {
          bathImport(row){
            this.$refs.bathImportAppraise.open(row)
          },
          exportAppraise(item){
            this.$refs.exportAppraiseModal.open(item)
          },
          goHref(url){
            window.open(url)
          },
          edit({taskId}){
            this.$router.push({path:'/pageIndex/taskTwo',query:{taskId}})
          },
          copyTask({taskId}){
            this.$confirm('确定复制吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$axios.post(COPY,{taskId}).then(resp=>{
                if(resp.code == 200){
                  this.$mes({message:resp.msg})
                  this.listForm.pageIndex = 1;
                  this.getList()
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              });
            });
          },
          delectTask({taskId}){
            this.$confirm('确定删除任务吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$axios.delete(`${LOGIC}${taskId}`,).then(resp=>{
                if(resp.code == 200){
                  this.$mes({message:resp.msg})
                  this.listForm.pageIndex = 1;
                  this.getList()
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              });
            });
          },
          pushTask({taskId}){
            this.$confirm('确定发布任务吗?', '提示', {
              confirmButtonText: '确定',
              cancelButtonText: '取消',
              type: 'warning'
            }).then(() => {
              this.$axios.post(`${PUSH}`,{taskIds:taskId}).then(resp=>{
                if(resp.code == 200){
                  this.$mes({message:resp.msg})
                  this.listForm.pageIndex = 1;
                  this.getList()
                }
              })
            }).catch(() => {
              this.$message({
                type: 'info',
                message: '已取消'
              });
            });
          },
          seeBillInfo(row){
            const info = {}
            this.$refs.billInfo.open(info,row)
          },
          submitFlagForm(index){
            const {orderId,pushFlag,pushNotes}  = this.flagForm
            this.$axios.put(`/order/update/push/flag/${orderId}`,{pushFlag,pushNotes}).then(resp=>{
              if(resp.code == 200){
                // this.taskList[index].pushFlag = this.flagForm.pushFlag;
                this.$set(this.taskList[index],'pushFlag',this.flagForm.pushFlag)
                this.$set(this.taskList[index],'pushNotes',this.flagForm.pushNotes)
                this.$set(this.taskList[index],'showPopver',false)
                console.log(this.taskList[index].showPopver)
                this.flagForm.pushFlag = '';
                this.flagForm.pushNotes = '';
                this.$mes({message:resp.msg})
              }
            })
          },
          changeFlag(row){
            this.showPopver = true;
            this.flagForm = {
              orderId:row.id,
              pushFlag:row.pushFlag,
              pushNotes:row.pushNotes,
            }
          },
          handleSizeChange(val){
              this.listForm.pageSize = val;
              this.getList()
          },
          handleCurrentChange(val){
              this.listForm.pageIndex = val;
              this.getList()
          },

          formatJson: function(filterVal, jsonData) {
              return jsonData.map(v => filterVal.map(j => {
                  return v[j]
              }))
          },
          reset(form){
              for (let item in form){
                  this.listForm[item] = form[item]
              }
              this.listForm.pageIndex = 1;
              this.getList()

          },
          onSubmit(form){
              for (let item in form){
                  this.listForm[item] = form[item]
              }
              this.listForm.pageIndex = 1;
              this.getList()
          },
          selectAll(flag){
              this.taskList.forEach(item=>{
                  item.flag = flag;
                  // item['flag'] = flag;
              })
          },
          // oneChange(flag,index){
              // this.taskList[index].flag = flag;
              // console.log(this.taskList[index].flag)
          // },
          checkChange(flag){
              console.log(flag)
          },
          onChange: (selectedRowKeys, selectedRows) => {
              console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          },
          onSelect: (record, selected, selectedRows) => {
              console.log(record, selected, selectedRows);
          },
          onSelectAll: (selected, selectedRows, changeRows) => {
              console.log(selected, selectedRows, changeRows);
          },
          exportExcel(){
            if(this.selectList.length== 0){
              return this.$mes({
                message: '请至少选择一条数据',
                type:'warning'
              })
            }
            import('@/tools/Export2Excel').then(excel => {
              const tHeader = ['订单编号','买家旺旺','平台', '店铺', '商品编号', '商品套餐','应付金额', '实付金额', '服务费','预计下单时间','任务状态 ',]
              const filterVal = ['orderNo', 'accountName','platformName', 'shopName', 'goodsNo', 'goodsSeamen', 'totalPayablePrice',  'copingPrice', 'serviceFee','beginTime','statusLabel', ]
              const list = this.selectList;
              const data = this.formatJson(filterVal, list);
              excel.export_json_to_excel({
                header: tHeader,
                data,
                filename: '订单数据',
                autoWidth: true,
                bookType: 'xlsx'
              })
            })
          },
          formmateTime(time){
              let nowDate = new Date(time);
              let year = nowDate.getFullYear();
              let month = nowDate.getMonth() + 1 < 10 ? "0" + (nowDate.getMonth() + 1) : nowDate.getMonth() + 1;
              let day = nowDate.getDate() < 10 ? "0" + nowDate.getDate() : nowDate  .getDate();
              return  year + "-" + month + "-" + day;
          },
          getList(){
              this.allFlag = false;
              const loading = this.$loading();
              this.$axios.get(TASK,this.listForm).then(resp=>{
                  this.loading = false;
                  loading.close()
                  if(resp.code == 200){
                      const taskList = resp.data.data;
                      this.total = resp.data.totalRow;
                      taskList.forEach(item=>{
                          item['flag'] = false;
                          item.goodsPicture = ImgUrl+item.goodsPicture
                      })
                      this.taskList = taskList;
                  }
              }).catch(()=>{
                  loading.close()
              })
          }
        },
        components: {
          SbSearch,
          BillInfo,
          ExportAppraiseModal,
          BathImportAppraise,
        },
        mounted() {
            const newData = this.formmateTime(new Date())
            this.listForm.beginDate = newData;
            this.listForm.endDate = newData;
            this.getList()
        }
    }
</script>

<style scoped lang="less">
	/deep/ .el-alert.is-light .el-alert__closebtn {
		color: white !important;
	}
    @borderColor: 1px solid rgb(224,224,224);
	.el-alert--info{
		background-color: @primaryColorQianHover !important;
		color: white !important;
		margin-bottom: 10px;
		
		
		
	}
    .title{
      width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    .radioBox{
      /deep/ .el-radio{
        margin-right: 5px;
      }
    }
    .overflow{
        display: inline-block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
    .border{
        border: @borderColor;
        padding: 5px 0;

    }
    .margin{
        margin-right: 20px;
    }
    .onePadding{
        margin: 8px 0;
        .flag{
            float: right;
            margin-right: 10px;
            cursor:pointer;
        }
    }
    .base{
        display: flex;
        align-items: center;
        font-size: 12px;
        padding: 5px 0;
        .baseInfo{
            margin-left: 8px;
        }

    }
    .content{
        background: white;
        padding: 20px;
        .headTr{
            background: #f5f5f5;

        }
        tbody::before {
            content: '';
            display: block;
            height: 20px;
            width: 105%;

        }
        .active{
            font-size: 12px;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        .td{
            padding:  0 8px 10px;
        }
    }
</style>
