<template>
  <div>
    <el-dialog :title="title" :visible.sync="dialogVisible" :before-close="handleClose" :close-on-click-modal="false"  width="1100px">
    <div>
      <el-button type="primary" @click="pushAppraise">
        新增评价
      </el-button>
    </div>
      <div>

      </div>
     <div>
       <el-table
           :data="appraiseList"
           height="400"
           style="width: 100%">

         <el-table-column
             type="index">
         </el-table-column>

         <el-table-column
             prop="content"
             label="评语"
             width="220">
         </el-table-column>

         <el-table-column
             prop="picture"
             label="图片">
           <template slot-scope="scope" >
             <div style="display: flex;flex-wrap: wrap;">
               <sb-image
                   style="margin-right: 10px"
                   v-for="(item,index) in  format(scope.row.picture)"
                   :key="index"
                   width="50px"
                   height="50px"
                   :src="item"
                   :src-list="[item]">
               </sb-image>
             </div>

           </template>
         </el-table-column>

         <el-table-column
             prop="picture"
             width="80"
             label="视频">
           <template slot-scope="scope" >
             <div>
               {{scope.row.videoUrl?'已上传':''}}
             </div>
           </template>
         </el-table-column>

         <el-table-column
             prop="price"
             width="80"
             label="评价费用">
           <template slot-scope="scope" >
             <div>
               {{scope.row.price}}元
             </div>
           </template>
         </el-table-column>

         <el-table-column
             width="80"
             label="操作">
           <template slot-scope="scope" >
             <div>
               <el-link type="primary" @click="deleteAppraise(scope.row)">删除</el-link>
             </div>
           </template>
         </el-table-column>

       </el-table>
     </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="handleClose">取 消</el-button>
        <el-button type="primary" @click="submit" :loading="loading">提 交 </el-button>
      </span>
    </el-dialog>
    <add-appraise ref="addAppraise" @add="add"></add-appraise>
  </div>
</template>

<script>
import AddAppraise  from './addAppraise';

import { BATCHAPPRAISE} from "../../../api/base";
import SbImage from "../../../components/image/image";

export default {
  name: "bathImportAppraise",
  data(){
    return {
      dialogVisible:false,
      loading:false,
      title:'发布评价',
      appraiseList:[],
      taskId:'',
    }
  },
  components:{
    AddAppraise,
    SbImage,
  },
  methods:{
    deleteAppraise(row){
      this.$confirm('确定删除吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        let index = this.appraiseList.findIndex((item)=>{
          return item.id == row.id;
        })
        this.appraiseList.splice(index,1)
      })
    },
    format(value){
      if(value){
        return  value.split(',')
      }else {
        return []
      }
    },
    handleClose(){
      this.$confirm('确定退出吗？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then((info) => {
        console.log(info);
        if(info == 'confirm'){
          this.dialogVisible = false;
        }
      })
    },
    add(map){
      this.appraiseList.push(map)
    },
    pushAppraise(){
      this.$refs.addAppraise.open()
    },
    remore(fileList){
      if(fileList.length == 0){
        this.form.picture = ''
      }else {
        this.setPrice(fileList)
      }
    },
    setPrice(fileList){
      this.priceList = fileList;
      let arr = []
      fileList.forEach(item=>{
        arr.push(item.url)
      })
      console.log(arr)
      this.form.picture =arr.join(',')
    },

    submit(){
        if(this.appraiseList.length != 0){
          const appraiseList = [...this.appraiseList]
          appraiseList.forEach(item=>[
              delete  item.id
          ])
          const map = {
            content: JSON.stringify(appraiseList),
            taskId:this.taskId,
          }
          this.loading = true;
          this.$axios.post(BATCHAPPRAISE,map).then(resp=>{
            this.loading = false;
            if(resp.code == 200){
              this.dialogVisible = false;
              this.$mes({message:resp.msg})
              this.$emit('success')
            }
          }).catch(()=>{
            this.loading = false;
          })
        }else{
          this.$mes({type:'warning',message:'请上传评价'})
        }
    },
    open(row){
      this.dialogVisible = true;
      this.appraiseList = []
      if(row.accountName){
        this.title ='买家旺旺：'+row.accountName;
        this.form.orderId = row.id;
      }else{
        this.taskId = row.taskId;
      }
      this.$refs.uploadList?.clear();
      this.$refs.uploadVideo?.clear();

    }
  }
}
</script>

<style scoped>

</style>