<template>
  <el-dialog  :title="title" :visible.sync="dialogVisible" :before-close="handleClose">
    <div>
<!--  基础费用    -->
      <el-descriptions class="margin-top" title="基础费用"   border :column="2" style="margin-bottom: 20px">
        <!--      <template slot="extra">-->
        <!--        <el-button type="primary" size="small">操作</el-button>-->
        <!--      </template>-->
        <!-- 商品本金(金额*单量*件数) -->
        <el-descriptions-item>
          <template slot="label">
            {{info.taskReceiptVo.principalName}}
          </template>
          <div v-for="item in  split(info.taskReceiptVo.principalDesc)" :key="item">
            {{item}}<br/>
          </div>
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            小计
          </template>
          {{info.taskReceiptVo.principalAmount}}
        </el-descriptions-item>


        <!-- 增值服务费 -->
        <el-descriptions-item :label="info.taskReceiptVo.serviceName"  v-if="split(info.taskReceiptVo.serviceDesc).length !=0">
          <div v-for="item in  split(info.taskReceiptVo.serviceDesc)" :key="item">
            {{item}}<br/>
          </div>
        </el-descriptions-item>

        <el-descriptions-item label="小计" >
          {{info.taskReceiptVo.serviceAmount}}
        </el-descriptions-item>
        <!--  -->
        <el-descriptions-item :label="info.taskReceiptVo.commissionName"     v-if="info.taskReceiptVo.commissionName">
          <div v-for="item in  split(info.taskReceiptVo.commissionDesc)" :key="item">
            {{item}}<br/>
          </div>
        </el-descriptions-item>
        <el-descriptions-item label="小计" v-if="info.taskReceiptVo.commissionAmount">
          {{info.taskReceiptVo.commissionAmount}}
        </el-descriptions-item>
        <!-- 合计 -->
        <el-descriptions-item label="合计" :span="24">
          {{info.taskReceiptVo.totalAmount}}
        </el-descriptions-item>
      </el-descriptions>

<!--  增值服务    -->
<!--      <el-descriptions  title="增值服务"   border :column="4" style="margin-bottom: 20px">-->
<!--        <el-descriptions-item label="是否允许使用信用卡/花呗/白条" :span="2">-->
<!--          {{info.taskWorthVo.allowCreditFlag?'是':'否'}}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item label="是否需要账号开通花呗/白条" :span="2">-->
<!--          <span v-if="info.taskWorthVo.huaBeiFlag == 0">没要求</span>-->
<!--          <span v-if="info.taskWorthVo.huaBeiFlag == 1">需要(1.5元/单)</span>-->
<!--          <span v-if="info.taskWorthVo.huaBeiFlag == 2">不需要(1.5元/单)</span>-->
<!--        </el-descriptions-item>-->

<!--        <el-descriptions-item label="新买家下单" :span="2">-->
<!--          {{info.taskWorthVo.newUserFlag?'需要(1.5元/单)':'不需要'}}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item label="老用户下单" :span="2">-->
<!--          {{info.taskWorthVo.oldUserFlag?'需要':'不需要'}}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item label="问题内容" :span="2">-->
<!--          <div v-for="(item,index) in split(info.taskWorthVo.issueContent)" :key="index">-->
<!--            {{item}}-->
<!--          </div>-->
<!--          <div v-if="split(info.taskWorthVo.issueContent).length == 0">没</div>-->
<!--        </el-descriptions-item>-->
<!--      </el-descriptions>-->

<!--  主商品进店方式-->
      <el-descriptions  title="主商品进店方式"   style="margin-bottom: 20px" v-if="info.taskKeywordsVo&&info.taskKeywordsVo.length!=0"></el-descriptions>
      <div v-if="info.taskKeywordsVo&&info.taskKeywordsVo.length!=0">
        <el-descriptions border v-for="(item,index) in info.taskKeywordsVo" :column="2"
                         :key="index" >
          <el-descriptions-item :span="1"
                                :label="item.entranceName"
          >
            <div style="width: 100px">
              <shop-url :item="item"></shop-url>
            </div>
          </el-descriptions-item>
          <el-descriptions-item :span="1" label="发布/剩余">
            <div style="width: 100px">
              {{item.totalQuantity}}/ {{item.surplusQuantity}}
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
<!--      附加商品信息-->
      <el-descriptions title="附加商品信息" v-if="info.taskAttachGoodsVo&&info.taskAttachGoodsVo.length!=0">
      </el-descriptions>
      <div
          v-if="info.taskAttachGoodsVo&&info.taskAttachGoodsVo.length!=0">
        <el-descriptions :key="index" border
                        style="margin-bottom: 25px;" v-for="(item,index) in info.taskAttachGoodsVo">
          <el-descriptions-item :span="24" label="附加商品编号">
            <div style="text-align: center">
              {{index+1}}
            </div>
          </el-descriptions-item>
          <el-descriptions-item label="商品标题" :span="24">
            <div>
              {{item.goodsTitle}}
            </div>
          </el-descriptions-item>

          <el-descriptions-item label="商品链接" :span="24">
            <div class="ellipsis">
              <a :href="item.goodsLink" target="_blank">{{item.goodsLink}}</a>
            </div>
          </el-descriptions-item>

          <el-descriptions-item label="商品图片">
            <div style="width: 60px;">
              <sb-avatar-check :url="item.goodsPicture"></sb-avatar-check>

            </div>
          </el-descriptions-item>

          <el-descriptions-item label="商品套餐">
            <div style="width: 60px;">
              {{item.goodsSeamen}}
            </div>
          </el-descriptions-item>

          <el-descriptions-item label="店内搜索词">
            <div style="width: 120px;">
              {{item.shopKeyword}}
            </div>
          </el-descriptions-item>

          <el-descriptions-item label="显示价">
            <div style="width: 60px;">
              {{item.displayPrice}}
            </div>
          </el-descriptions-item>

          <el-descriptions-item label="实付单价">
            <div style="width: 120px;">
              {{item.unitPrice}}
            </div>
          </el-descriptions-item>

          <el-descriptions-item label="件数">
            <div style="width: 120px;">
              {{item.buyAmount}}
            </div>
          </el-descriptions-item>

        </el-descriptions>
      </div>
      <!-- 付款时段 -->
      <el-descriptions title="付款时段" v-if="info.taskBuyHourVo&&info.taskBuyHourVo.length!=0"></el-descriptions>
      <div style="max-height: 200px;overflow-y: auto;margin-bottom: 20px"
           v-if="info.taskBuyHourVo&&info.taskBuyHourVo.length!=0">
        <el-descriptions border>

          <el-descriptions-item :label="item.keyHour+'点'" v-for="(item,index) in info.taskBuyHourVo"
                               :key="index">
            <div style="width: 120px">
              发布/剩余：{{item.totalQuantity}}/{{item.surplusQuantity}}
            </div>
          </el-descriptions-item>
        </el-descriptions>
      </div>
      <!-- 筛选条件 -->
<!--      <el-descriptions title="筛选条件" v-if="info.taskScreeningVo"></el-descriptions>-->
<!--      <el-descriptions border v-if="info.taskScreeningVo" style="margin-bottom: 20px">-->
<!--        <el-descriptions-item label="省市">-->
<!--          {{info.taskScreeningVo.location}}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item label="价格区间">-->
<!--          {{info.taskScreeningVo.rangePrice}}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item label="收货人数约">-->
<!--          {{info.taskScreeningVo.receiptNum}}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item label="排序方式">-->
<!--          {{info.taskScreeningVo.sortBy}}-->
<!--        </el-descriptions-item>-->
<!--        <el-descriptions-item label="已售数量">-->
<!--          {{info.taskScreeningVo.soldNum}}-->
<!--        </el-descriptions-item>-->
<!--      </el-descriptions>-->
    </div>
  </el-dialog>


</template>

<script>
export default {
  name: "billInfo",
  data(){
    return {
      dialogVisible:false,
      title:'',
      info: {
        taskBuyHourVo:[],
        taskKeywordsVo:[],
        taskAttachGoodsVo:[],
        taskSafetyVo:{},
        taskScreeningVo:{},
        taskWorthVo:{},
        taskReceiptVo:{},
      },
    }
  },
  props:{

  },
  methods:{
    split(data) {
      if (data) {
        return data.split(',')
      } else {
        return []
      }
    },
    open(info,row){
      console.log(info)
        if(!info.commissionDesc){
          this.title =  row.shopName
        }else{
          this.title = row.sellerName + ' / ' + row.shopName + ' / ' + row.goodsTitle;
        }
        this.info.taskReceiptVo = row.receiptVo;
        this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
      this.$emit('handleClose')
    },
  }
}
</script>

<style scoped>

</style>