<template>
	<div>
		<el-dialog
				:title="title"
				:visible.sync="dialogVisible"
				width="951"
				:before-close="handleClose"
				:close-on-click-modal="false">

			<div>
				<el-form ref="ruleForm" :model="form" label-width="100px" :rules="formRules" size="medium">

					<el-form-item label="评价模板：" prop="file">
            <el-upload
                drag
                action="#"
                :fileList="fileList"
                :on-change="uploadExcelChange"
                :on-remore="uploadExcelRemove"
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
                >
              <i class="el-icon-upload"></i>
              <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
              <div class="el-upload__tip" slot="tip">xls/xlsx文件</div>
            </el-upload>
					</el-form-item>
					<el-form-item label="导入模板：" >
            <a :href="downloadUrl">
              <a-icon type="download"/>
              下载模板
            </a>
					</el-form-item>

				</el-form>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="save" :loading="loading">提 交</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	// beforeCreate created beforeMount mounted 都不会触发。
  import {APPRAISEDOWNLOAD, IMPORTAPPRAISE} from '../../../api/base'


	// import SbAdress from '../../components/adress/adress.vue'

	export default {
		data() {
			return {
				dialogVisible: false,
				loading: false,
        fileList:[],
        downloadUrl:process.env.VUE_APP_API_BASE_URL+APPRAISEDOWNLOAD,
        form: {
          file: "",
          taskId: '',
				},
				formRules: {
          file: [{
						required: true,
						message: '请上传评价模板',
						trigger: 'change'
					},],
				}
			}
		},
		props: {
			title: {
				type: String,
				default: () => {
					return ''
				}
			},
			name: {
				type: Array,
				default: () => {
					return []
				}
			}
		},
		methods: {
      uploadExcelChange(file){
          this.fileList = [file];
      },
      uploadExcelRemove(file){
        console.log(file)
        this.fileList = []
      },
			save() {
        this.form.file = this.fileList[0];
        console.log(this.fileList[0])
				this.$refs.ruleForm.validate((validate) => {
					// 验证通过
					if (validate) {
						this.loading = true;
            const fd = new FormData()
            fd.append('file',this.form.file.raw);
            fd.append('taskId',this.form.taskId)
            this.$axios.form(IMPORTAPPRAISE, fd ).then(resp => {
              console.log(resp)
              this.loading = false;
              if (resp.code == 200) {
                this.$mes({message: resp.msg})
                this.dialogVisible = false;
                this.fileList = []
                this.$emit('successHandel')
              } else {
                this.$mes({message: resp.msg, type: 'warning'})
              }
            }).catch(()=>{
              this.loading = false;
            })
					}
				})
			},
			handleClose() {
				this.dialogVisible = false;
				this.$emit('handleClose');

			},
			open(row) {
				this.dialogVisible = true;
				this.loading = false;

				this.$nextTick(() => {
					if (row) {
						this.$refs.ruleForm.resetFields()
						this.form.taskId = row.taskId;
					} else {
						this.$refs.ruleForm.resetFields()
					}
				})
			},
			stop() {
				this.dialogVisible = false;
			}
		},
		components: {
			// SbAdress,
		},
		activated: {
			// keep-alive组件 激活 时调用。
			//该钩子在服务器端渲染期间不被调用。

		},
		deactivated: {
			//keep-alive组件  停用 时调用。
			//该钩子在服务器端渲染期间不被调用。
		},
		watch: {
			// data(newValue, oldValue) {

			// }
		},
		filters: {
			// fnName: function(value) {
			// 	return value;
			// }
		},
		beforeCreate() {

		},
		created() {

		},
		beforeMount() {

		},
		mounted() {

		},
		beforeUpdate() {

		},
		updated() {

		},
		beforeDestroy() {

		},
		destroyed() {

		},
		computed: {
			// name() {
			// 	return this.data
			// }
		},

	}
</script>

<style lang="less" scoped>
	/deep/ .el-select .el-input.is-focus .el-input__inner{
		border-color: @primaryColorQian;
	}
	/deep/ .el-input__inner:focus {
		border-color: @primaryColorQian;
	}
</style>
